import styled from 'styled-components';
import { Text } from '../../helpers';
import RoundBox from '../RoundBox/RoundBox';
import AppStoreButton from './AppStoreButton';
import GooglePlayButton from './GooglePlayButton';

const ImageWrapper = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: baseline;
    height: 55px;
`;

const MobileAppsButtons = () => {
    return (
        <RoundBox>
            <Text
                i="Börja använda tjänsten du också, redan idag!"
                style={{ textAlign: 'center', marginBottom: '17px' }}
            />
            <ImageWrapper>
                <AppStoreButton width="142px" />
                <GooglePlayButton width="152px" />
            </ImageWrapper>
        </RoundBox>
    );
};

export default MobileAppsButtons;
