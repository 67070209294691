import { ReactElement } from 'react';
import { colors } from '../../assets/colors';
import { customerKeyToDisplayName, getCustomerFieldKeys } from '../../functions';
import { Text, RowView } from '../../helpers';
import { IInvoice } from '../../interfaces';
import { useGlobalState } from '../../state';
import RoundBox from '../RoundBox/RoundBox';

// This is a grey box

type AddressProp = {
    setShowInfo: any;
};

const Address = ({ setShowInfo }: AddressProp) => {
    const [invoice] = useGlobalState('invoice');
    const customer: IInvoice['customer'] = invoice['customer'];

    const customer_field_keys = getCustomerFieldKeys(customer);
    return (
        <RoundBox
            style={{
                borderWidth: '1px',
                borderColor: colors.dark,
                borderStyle: 'solid',
                background: '#f7f7fa',
                marginTop: '2vh',
                position: 'relative'
            }}>
            <Text i={customer.name} fontWeight="600" large color={colors.dark} />
            <div style={{ marginTop: '1vh', marginBottom: '1vh' }}>
                {customer_field_keys.map(
                    (key, index) =>
                        customerKeyToDisplayName(key) !== '' &&
                        key !== 'name' && (
                            <RowView
                                key={index}
                                style={{
                                    justifyContent: 'flex-start',
                                    marginBottom: '5px',
                                    gap: '2px'
                                }}>
                                <Text
                                    fontWeight="400"
                                    i={customerKeyToDisplayName(key) + ':'}
                                    color={colors.lightGrey}
                                />
                                <Text
                                    i={customer[key as keyof typeof customer]}
                                    color={colors.lightGrey}
                                />
                            </RowView>
                        )
                )}
            </div>
            <div
                style={{
                    position: 'absolute',
                    color: colors.darkGrey,
                    fontWeight: 'normal',
                    textDecoration: 'none',
                    top: '24px',
                    right: '24px',
                    cursor: 'pointer'
                }}
                onClick={() => setShowInfo('manual')}>
                Ändra uppgifter
            </div>
        </RoundBox>
    );
};

export default Address;
